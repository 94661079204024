import React from 'react';

import { Helmet } from 'react-helmet';
import about from '../../shared/data/about.json';
import contactDetails from '../../shared/data/contactDetails.json';
import projects from '../../shared/data/projects.json';
import recommendations from '../../shared/data/recommendations.json';
import skills from '../../shared/data/skills.json';
import specialization from '../../shared/data/specialization.json';
import timeline from '../../shared/data/timeline.json';

export default () => (
  <Helmet>
    <script type="text/javascript">{`var ___initial_data = ${JSON.stringify({
      about,
      contactDetails,
      projects,
      recommendations,
      skills,
      specialization,
      timeline,
    })}`}</script>
  </Helmet>
);
