/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import '../static/application/common/scss/app.scss';

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <main>{children}</main>
        <footer className="flex items-center justify-center p-5">
          © {new Date().getFullYear()}, in&nbsp;{' '}
          <span role="img" aria-label="heart">
            ❤️
          </span>{' '}
          &nbsp;with&nbsp;
          <a href="https://en.wikipedia.org/wiki/Batman">Batman</a>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
