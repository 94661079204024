import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import InitializeData from './InitializeData';

function SEO({ description, title, image }) {
  const { pathname } = useLocation();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          defaultDescription: description
          author
          titleTemplate
          siteUrl: url
          defaultImage: image
          twitterUsername
        }
      }
      mobileImage: file(childImageSharp: { fixed: { originalName: { eq: "me-245.jpg" } } }) {
        childImageSharp {
          fixed(width: 245, height: 381) {
            ...GatsbyImageSharpFixed
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      desktopImage: file(childImageSharp: { fixed: { originalName: { eq: "me.jpg" } } }) {
        childImageSharp {
          fixed(width: 300, height: 466) {
            ...GatsbyImageSharpFixed
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const { site, mobileImage, desktopImage } = data;
  const { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage, twitterUsername } = site.siteMetadata;
  const { srcWebp: mobileImg, src: mobileOriginalImg } = mobileImage.childImageSharp.fixed;
  const { srcWebp: desktopImg, src: desktopOriginalImg } = desktopImage.childImageSharp.fixed;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }} title={seo.title} titleTemplate={titleTemplate}>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        <meta property="og:type" content="website" />
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && <meta property="og:description" content={seo.description} />}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && <meta name="twitter:description" content={seo.description} />}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
        <link rel="preload" as="image" href={mobileImg} />
        <link rel="preload" as="image" href={desktopImg} />
        <link rel="preload" as="image" href={mobileOriginalImg} />
        <link rel="preload" as="image" href={desktopOriginalImg} />
      </Helmet>

      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Person",
               "address": {
                "@type": "PostalAddress",
                "addressLocality": "Mumbai",
                "addressRegion": "MH",
                "postalCode": "",
                "streetAddress": ""
              },
              "email": "mailto:shivamsupr@gmail.com",
              "image": "${seo.image}",
              "jobTitle": "Web Architect",
              "name": "Shivam Rastogi",
              "telephone": "",
              "url": "${siteUrl}"
            }
          `}
        </script>
      </Helmet>
      <InitializeData />
    </>
  );
}

SEO.defaultProps = {
  description: '',
  title: '',
  image: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
};

export default SEO;
